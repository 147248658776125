<template>
  <div>
    <label v-show="label" class="input-label" :for="'dropfile-' + _uid">{{
      label
    }}</label>
    <div
      :class="['nb-drop-file', { isDragging: isDragging }, imgClass]"
      @dragover="onDragOver"
      @dragleave="onDragLeave"
      @drop="onDrop"
      @click="trigger"
    >
      <input
        type="file"
        :multiple="multiple"
        @change="onChange"
        ref="file"
        :accept="fileTypes"
        :id="'dropfile-' + _uid"
      />

      <img
        v-if="imgShow && !files.length"
        :src="imgShow"
        class="logo-previsualization"
        :style="`width: ${imgWidth}; height: ${imgHeight}`"
      />
      <slot v-else>
        <p class="body-3 m-0 text-center">
          {{ $t("dragDropFile") }}
        </p>
      </slot>

      <PreviewFiles v-if="files.length" :files="files" @remove="remove" />
    </div>
    <ErrorFeedback :error="errors[0]" />
  </div>
</template>

<script>
import ErrorFeedback from "../../generic/ErrorFeedback.vue";
import PreviewFiles from "./PreviewFiles.vue";

export default {
  name: "NbDropFile",
  components: {
    PreviewFiles,
    ErrorFeedback,
  },
  props: {
    value: {
      type: [String, File],
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    accept: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    imgShow: {
      type: String,
      default: "",
    },
    imgHeight: {
      type: String,
      required: false,
    },
    imgWidth: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isDragging: false,
      files: [],
      errors: [],
    };
  },
  computed: {
    fileTypes() {
      return this.accept.length ? this.accept.join(",") : "";
    },
    imgClass() {
      if (this.imgShow && !this.files.length) {
        return "img-show";
      }
      return "";
    },
  },
  methods: {
    trigger(event) {
      event.stopPropagation();
      this.$refs.file?.click();
    },
    onDragOver(event) {
      event.preventDefault();
      this.isDragging = true;
    },
    onDragLeave() {
      this.isDragging = false;
    },
    onDrop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
    validFiles(files) {
      if (!this.accept.length) return true;

      const sanitizeAcceptFiles = this.accept.map((item) =>
        item
          .replace(/[^A-z/]/g, "")
          ?.split("/")
          ?.pop()
      );

      return Array.from(files).every((file) => {
        return sanitizeAcceptFiles.includes(file.type.split("/").pop());
      });
    },
    onChange() {
      const inputFiles = this.$refs.file.files;
      if (!inputFiles.length) return;

      this.errors = [];

      if (!this.validFiles(inputFiles)) {
        this.errors.push(
          `${this.$t("invalidFileFormat")}: ${this.accept.join(",")}`
        );
        return;
      }

      if (this.multiple) {
        this.files.push(...inputFiles);
      } else {
        this.files = [this.$refs.file.files[0]];
      }
      this.emitFiles();
    },
    remove(index) {
      this.files.splice(index, 1);
      this.emitFiles();

      if (!this.files || !this.files.length) {
        this.$refs.file.value = "";
      }
    },
    emitFiles() {
      if (this.files.length) {
        this.$emit("input", this.multiple ? this.files : this.files[0]);
        return;
      }

      this.$emit("input", null);
    },
  },
  watch: {
    value(newValue) {
      if (newValue === "") this.files = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.nb-drop-file {
  &.img-show {
    padding: 0rem;
    border: 2px solid var(--gray-10);
    background-color: var(--gray-10);
  }
  border-radius: 8px;
  padding: 1.5rem;
  border: 2px dashed var(--gray-40);
  &:hover,
  &.isDragging {
    background-color: var(--gray-10);
  }

  &.isDragging {
    border-color: var(--primary);
  }

  input {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
  }

  .logo-previsualization {
    object-fit: contain;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
</style>
