import HttpService from "./HttpService";

export default class LogService {
  constructor() {
    this.httpService = HttpService;
  }

  /**
   * Get log
   *
   * @param {*} id
   * @returns {Promise}
   */
  getLog(id = null) {
    return this.httpService.get("/v1/logs/" + id);
  }

  cancelImport(id) {
    return this.httpService.post("/v1/cancel-import/" + id);
  }

  validImport(log) {
    return this.httpService.post("/v1/confirm-import", { log: log });
  }

  removeOrder(indexes) {
    return this.httpService.put("/v1/delete-rejected", { order: indexes });
  }

  validateOrder(logIndex) {
    return this.httpService.put("/v1/validate-rejected/" + logIndex);
  }

  updateOrderLog(data, logIndex) {
    return this.httpService.put("/v1/update-rejected/" + logIndex, {
      order_log: data,
    });
  }
}
