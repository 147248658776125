import HttpService from "./HttpService";

export default class ContractService {
  constructor() {
    this.httpService = HttpService;
  }

  getContracts() {
    return this.httpService.get("/v1/contracts-names");
  }

  getContractsByCep(postal_code, incoterm, value, currency) {
    let filter =
      "?cep=" +
      postal_code +
      "&incoterm=" +
      incoterm +
      "&value=" +
      value +
      "&currency=" +
      currency;
    return this.httpService.get("/v1/contracts-by-cep" + filter);
  }

  getContractsActive() {
    return this.httpService.get("/v1/contracts-active");
  }

  getContractsSellerActive() {
    return this.httpService.get("/v1/contracts-active-seller");
  }

  updateContractSeller(id, data) {
    return this.httpService.put("/v1/edit_contract_seller/" + id, data);
  }

  setObsolete(id, data) {
    return this.httpService.put("/v1/set_obsolete/" + id, data);
  }

  getContract(id = null) {
    return this.httpService.get("/v1/contracts/" + id);
  }

  getAutomaticPayment() {
    return this.httpService.get("/v1/contracts-automatic-payment");
  }

  setSellerContract(data) {
    return this.httpService.post("/v1/seller_create_contract", data);
  }
}
