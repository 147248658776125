<template>
  <div>
    <NbPageTitle :title="$t('ordersImportPage.title') + ' test'" />

    <div class="content ml-3">
      <NbCardBox>
        <NbTextInput
          v-model="name"
          :name="$t('name')"
          class="mb-4"
          required
          id="name"
        />
        <NbDropFile
          ref="dropFile"
          v-model="file"
          :label="$t('ordersImportPage.selectFile')"
          :accept="['text/csv']"
        >
          <div class="py-4">
            <p class="text-center">{{ $t("dragDropFile") }} (.csv)</p>

            <NbButton variant="secondary d-block mx-auto">{{
              $t("ordersImportPage.sendFile")
            }}</NbButton>
            <NbButton
              variant="secondary my-2 d-block mx-auto"
              @click.stop="downloadCsvExample"
              >{{ $t("ordersImportPage.downloadExampleFile") }}</NbButton
            >

            <div class="text-center">
              {{ $t("ordersImportPage.downloadHere") }}
              <a
                href="javascript:void;"
                @click="downloadInstructionsFile"
                target="_blank"
                class="text-center text-link"
              >
                {{ $t("ordersImportPage.instructionsXLSFile") }}
              </a>
            </div>
          </div>
        </NbDropFile>
      </NbCardBox>
      <div class="d-flex flex-column mt-2">
        <NbButton @click="upload" :disabled="!isValid || loading">{{
          $t("upload")
        }}</NbButton>
      </div>
    </div>
  </div>
</template>

<script>
import NbPageTitle from "../../components/pagescomponents/NbPageTitle.vue";
import NbDropFile from "@/components/input/drop-file/NbDropFile.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbCardBox from "@/components/cards/NbCardBox.vue";
import DownloadService from "../../services/DownloadsService.js";
import SellerService from "../../services/SellerService.js";
import ContractService from "../../services/ContractService.js";

const downloadsService = new DownloadService();
const sellerService = new SellerService();
const contractService = new ContractService();

export default {
  name: "Import",
  components: {
    NbPageTitle,
    NbDropFile,
    NbButton,
    NbTextInput,
    NbCardBox,
  },
  data() {
    return {
      file: null,
      name: "",
      loading: false,
      automaticPayment: false,
      currentSeller: {},
    };
  },
  computed: {
    isValid() {
      return this.name.trim() && this.file;
    },
    exampleFileLink() {
      if (!this.currentSeller.is_multiple && !this.automaticPayment) {
        return {
          xls: "/files/Instructions.xlsx",
          csv: "/files/sheet.csv",
        };
      }

      if (!this.currentSeller.is_multiple && this.automaticPayment) {
        return {
          xls: "/files/Instructions_autotax.xlsx",
          csv: "/files/sheet_autotax.csv",
        };
      }

      if (this.currentSeller.is_multiple && !this.automaticPayment) {
        return {
          xls: "/files/Instructions_multi_seller.xlsx",
          csv: "/files/sheet_multi_seller.csv",
        };
      }

      return {
        xls: "/files/Instructions_multi_seller_autotax.xlsx",
        csv: "/files/sheet_multi_seller_autotax.csv",
      };
    },
  },
  methods: {
    fetchCurrentSeller() {
      sellerService.getCurrentSeller().then((response) => {
        this.currentSeller = response.data.data;
      });
    },
    fetchAutomaticPayment() {
      contractService.getAutomaticPayment().then((response) => {
        this.automaticPayment = response.data.data;
      });
    },
    downloadCsvExample() {
      window.open(this.exampleFileLink.csv, "_blank");
    },
    downloadInstructionsFile() {
      window.open(this.exampleFileLink.xls, "_blank");
    },
    async upload() {
      if (!this.isValid) return;

      const formData = new FormData();
      formData.append("file", this.file, this.file?.name);
      formData.append("name", this.name);
      formData.append("data_type", "order_import");

      try {
        this.loading = true;
        await downloadsService.loadProcessing(formData);
        this.$router.push("/archives");
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.fetchCurrentSeller();
    this.fetchAutomaticPayment();
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 32.75rem;
}
</style>
