<template>
  <div class="page">
    <div class="mb-5">
      <h4 class="heading-4">{{ $t("importResultPage.title") }}</h4>
    </div>
    <div class="d-flex justify-content-between mb-5">
      <NbCard
        id="sumary-card"
        :title="$t('importResultPage.summary')"
        class="px-3 w-48-5"
      >
        <template #body>
          <div class="pt-3">
            <div>
              <span class="body-3">
                {{ $t("importResultPage.totalOrders") }}:
              </span>
              <span class="body-3-semibold">
                {{ total_rejected + total_valid }}
              </span>
            </div>
            <div>
              <span class="body-3">
                {{ $t("importResultPage.totalValid") }}:
              </span>
              <span class="body-3-semibold">
                {{ total_valid }}
              </span>
            </div>
            <div>
              <span class="body-3">
                {{ $t("importResultPage.totalRejected") }}:
              </span>
              <span class="body-3-semibold">
                {{ total_rejected }}
              </span>
            </div>
            <br />
          </div>
        </template>
      </NbCard>
      <NbCard
        id="sumary-card"
        :title="$t('actions')"
        class="px-3"
        width="23.4rem"
      >
        <template #body>
          <div class="d-flex justify-content-between flex-wrap pt-3">
            <NbButton
              class="w-48-5 my-1 px-2 text-left text-14-20"
              variant="secondary"
              data-toggle="modal"
              data-target="#modalCancelImport"
            >
              {{ $t("cancel") }}
            </NbButton>
            <NbButton
              id="first-mile-label"
              class="w-48-5 my-1 px-2 text-left text-14-20"
              variant="primary"
              :disabled="loadingImport"
              @click="validImport()"
            >
              <span class="d-flex">
                <NbSpinner
                  v-if="loadingImport"
                  class="text-white mr-1"
                  size="1.1rem"
                />
                {{ $t("confirm") }}
              </span>
            </NbButton>
          </div>
        </template>
      </NbCard>
    </div>
    <!-- :disabled="total_valid === 0"  -->

    <NbCard
      id="log-card"
      :title="$t('importResultPage.importLog')"
      class="px-3"
    >
      <template #body>
        <NbTablev2
          tableOf="downloadedFile"
          height="100%"
          :hiddeTableOptions="true"
          :allFields="allFieldsDownloadedFile"
          :fields="fieldsDownloadedFile"
          :hasParentItens="true"
          :startItems="downloadedFile || []"
          @reloadFields="fieldsDownloadedFile = $event"
        >
          <template #cell(order_number)="data">
            <div>
              {{ data.item.order.order_number }}
            </div>
          </template>
          <template #cell(status)="data">
            <div>
              <NbBadge
                :type="badgeType(data.item.errors)"
                :text="badgeText(data.item.errors)"
                class="fit-content"
              />
            </div>
          </template>
          <template #cell(logs)="data">
            <div
              class="link-1"
              data-toggle="modal"
              data-target="#codeView"
              @click="currentCodeView = data.item.errors"
            >
              {{ truncateString(data.item.errors, 65) }}
            </div>
          </template>
        </NbTablev2>
      </template>
    </NbCard>

    <NbFooter
      class="mt-1"
      :text="$t('components.footer.checkOurSupport')"
      link="https://nobordistinc.freshdesk.com/support/home"
    />
    <!-- modals -->
    <NbModal id="codeView" modalConfig="modal-dialog-centered" width="850px">
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("ordersRejectedPage.error") }}
        </div>
      </template>
      <template v-slot:body>
        <pre>
            <code>
{{ currentCodeView }} 
            </code>
          </pre>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton variant="secondary" data-dismiss="modal" aria-label="Close">
            {{ $t("close") }}
          </NbButton>
          <div></div>
        </div>
      </template>
    </NbModal>

    <NbModal id="modalCancelImport" modalConfig="modal-dialog-centered">
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("ordersRejectedPage.error") }}
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton variant="secondary" data-dismiss="modal" aria-label="Close">
            {{ $t("close") }}
          </NbButton>
          <NbButton
            variant="primary"
            data-dismiss="modal"
            aria-label="Close"
            @click="cancelImport()"
          >
            {{ $t("importResultPage.cancelImport") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
  </div>
</template>

<script>
import LogService from "@/services/LogService";
import NbCard from "@/components/cards/NbCard.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbSpinner from "@/components/loadings/NbSpinner.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import NbModal from "@/components/modal/NbModal.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";

import NProgress from "nprogress";
import DownloadsService from "@/services/DownloadsService";

const downloadsService = new DownloadsService();
const logService = new LogService();

export default {
  name: "ImportResponse",
  components: {
    NbCard,
    NbButton,
    NbSpinner,
    NbTablev2,
    NbBadge,
    NbModal,
    NbFooter,
  },
  data: () => {
    return {
      response: null,
      total_rejected: 0,
      total_valid: 0,
      loadingImport: false,
      downloadedFile: [],
      fieldsDownloadedFile: [],
      currentCodeView: {},
    };
  },
  beforeMount() {
    this.response = this.$router.history.current.query.orders;
    downloadsService.getAdditionaldata(this.response).then((response) => {
      this.downloadedFile = response.data.data.additional_data;
      for (let i = 0, l = this.downloadedFile.length; i < l; i++) {
        if (this.downloadedFile[i].errors.length === 0) {
          this.total_valid++;
        } else {
          this.total_rejected++;
        }
      }
    });
  },
  methods: {
    validImport() {
      this.loadingImport = true;

      logService
        .validImport(this.response)
        .then(() => {
          this.loadingImport = false;
          if (this.total_rejected > 0) {
            this.$router.push("/orders/rejected");
            // } else {
            //   this.$router.push("/orders/");
          } else {
            this.$router.push("/archives");
          }
        })
        .catch(() => {
          setTimeout(() => {
            this.loadingImport = false;
            NProgress.done();
          }, 500);
        });
    },
    cancelImport() {
      this.$router.push("/orders/import");
    },
    badgeText(errors) {
      if (errors && Object.keys(errors).length > 1) {
        return this.$t("erro");
      }
      return this.$t("importResultPage.success");
    },
    badgeType(errors) {
      if (errors && Object.keys(errors).length > 1) {
        return "danger";
      }
      return "success";
    },
    truncateString(str, limit) {
      let finalStr = str;
      if (typeof finalStr !== "string") {
        finalStr = JSON.stringify(str);
      }
      if (finalStr.length > limit) {
        return finalStr.substring(0, limit) + "(...)";
      }
      return finalStr;
    },
  },
  computed: {
    allFieldsDownloadedFile() {
      return [
        { key: "order_number", label: this.$t("importResultPage.orderNumber") },
        { key: "status", label: this.$t("importResultPage.status") },
        { key: "logs", label: this.$t("importResultPage.log") },
      ];
    },
  },
};
</script>
